import classnames from 'classnames';
import React, { useEffect } from 'react';

import Link from '../Link';

import { useLayoutState } from '../../layout/Context';

const LocaleNavigation = ({ locale }) => {
  return (
    <div className="flex">
      <Link
        to="/"
        className={classnames('typo-intro no-underline uppercase px-2', {
          'text-black bg-indigo-400': locale === 'uk',
          'text-indigo-400': locale !== 'uk',
        })}
      >
        UA
      </Link>
      <Link
        to="/en/"
        className={classnames('typo-intro no-underline uppercase px-2', {
          'text-black bg-indigo-400': locale === 'en',
          'text-indigo-400': locale !== 'en',
        })}
      >
        EN
      </Link>
    </div>
  );
};

export default LocaleNavigation;
