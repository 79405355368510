import React from 'react';
import FacebookIcon from '../components/icons/FacebookIcon';
import LinkedinIcon from '../components/icons/LinkedinIcon';
import YoutubeIcon from '../components/icons/YoutubeIcon';
import Link from '../components/Link';
import useTranslation from '../hooks/useTranslation';
import useUrlLocalization from '../hooks/useUrlLocalization';
import LogoSrc from '../images/logo-white.svg';
import { useLayoutState } from '../layout/Context';

const Footer = () => {
  const { locale } = useLayoutState();
  const { t } = useTranslation('footer', locale);
  const { url } = useUrlLocalization(locale);

  return (
    <footer className="bg-black">
      <div className="container-lg">
        <hr className="bg-white" />
        <div className="row items-center pt-16">
          <div className="col-full lg:col-6">
            <Link to={url('/')} className="block no-underline">
              <img src={LogoSrc} alt="Logo" className="h-10 lg:h-14" />
            </Link>
          </div>
          <div className="col-full lg:col-3 lg:ml-3/12 mt-8 lg:mt-0">
            <div className="flex lg:justify-end gap-4">
              <Link to="https://www.facebook.com/KyivInternationalCyberResilienceForum/">
                <FacebookIcon className="h-6 w-6" />
              </Link>
              <Link to="https://www.linkedin.com/company/kyiv-international-cyber-resilience-forum">
                <LinkedinIcon className="h-6 w-6" />
              </Link>
              <Link to="https://www.youtube.com/@KICRF">
                <YoutubeIcon className="h-6 w-6" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="container-lg text-center py-4">
          <small className="typo-small">
            © 2024 {t('All rights reserved')}.
            {' '}
            <Link to={url('/privacy-policy/')}>
              {t('Privacy policy')}
            </Link>
            .
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
