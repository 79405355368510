const useUrlLocalization = (scopeLocale) => {
  const url = (path, pathLocale) => {
    const locale = pathLocale || scopeLocale;

    if (locale === 'uk' || path.includes(`/${locale}`)) {
      return path;
    }

    return `/${locale}${path}`;
  };

  return { url };
};

export default useUrlLocalization;
