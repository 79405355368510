import React from 'react';
import Link from '../components/Link';
import LocaleSwitch from '../components/LocaleSwitch';
import LogoSrc from '../images/logo.svg';
import useTranslation from '../hooks/useTranslation';
import useUrlLocalization from '../hooks/useUrlLocalization';
import { useLayoutState } from '../layout/Context';

const Header = ({ path }) => {
  const { locale } = useLayoutState();
  const { t } = useTranslation('navigation', locale);
  const { url } = useUrlLocalization(locale);

  return (
    <>
      <header className="bg-black text-white fixed w-full left-0 top-0 right-0 z-20">
        <div className="container-lg flex items-center justify-between h-24 relative">
          <Link to={url('/')} className="block no-underline">
            <img src={LogoSrc} alt="Logo" className="h-10 lg:h-14" />
          </Link>
          <nav className="py-2 rounded flex gap-x-8 uppercase">
            <Link to="https://cyberforumkyiv.org/2024/" className="typo-intro text-green-400 no-underline">
              {t('KICRF – 2024')}
            </Link>
          </nav>
          <div className="absolute top-0 right-4">
            <LocaleSwitch locale={locale} path={path} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
