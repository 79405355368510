import classnames from 'classnames';
import * as React from 'react';
import { LayoutProvider } from './Context';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ uri, path, location, children }) => {
  return (
    <LayoutProvider path={location.pathname}>
      <Header path={location.pathname} />
      <main>
        {children}
      </main>
      <Footer />
    </LayoutProvider>
  );
};

export default Layout;
