exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-call-for-papers-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/en/call-for-papers/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-call-for-papers-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-privacy-policy-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/en/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-privacy-policy-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-call-for-papers-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/uk/call-for-papers/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-call-for-papers-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-privacy-policy-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/uk/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-privacy-policy-index-md" */)
}

