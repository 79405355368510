import { Link } from 'gatsby';
import React from 'react';
import PropTypes from '../../utils/PropTypes';

const AnchorLink = ({ to, className, children, onClick, ...rest }) => {
    // const withoutHash = to;
    let hash;
    if (to.includes('#')) {
        const hashIndex = to.lastIndexOf('#');
        // withoutHash = to.substring(0, hashIndex);
        hash = to.substring(hashIndex);
    }
    return (
        <Link
            to={to}
            className={className}
            onClick={() => {
                if (hash) {
                    window.gatsby_router_hash = hash;
                }
                if (onClick) {
                    onClick();
                }
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
        >
            {children}
        </Link>
    );
};

AnchorLink.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
};

AnchorLink.defaultProps = {
    to: '',
    className: '',
    children: '',
    onClick: null,
};

export default AnchorLink;
